import React, { useState, useEffect, useCallback } from "react";
import styles from './Ucl.module.scss';
import {Table,Container,Row,Col,Button} from "react-bootstrap"

import SnackAd from '../components/SnackAd'

const axios = require("axios");

function GwInfo(props) {

  const gameWeek = props.gwPage>0 ? ("Gameweek " + props.gwPage) : null

  return(
    <Container fluid className ={styles.gwInfo}>
      <Row>
        <Col xs={3}>
          <GwButton button_type="down" gwPage={props.gwPage} setUrl={props.setUrl} newUrl={props.newUrl} />
        </Col>
        <Col xs={6}>
          <h3 className ={styles.gameweekh1}>
            {gameWeek}
          </h3>
        </Col>
        <Col xs={3}>
          <GwButton button_type="up" gwPage={props.gwPage} setUrl={props.setUrl} newUrl={props.newUrl}/>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5 className ={styles.updateh5}>
            Page automatically refreshes
          </h5>
        </Col>
      </Row>
    </Container>
  )
}

function GwButton(props) {
  const newGW = props.button_type === "up" ? props.gwPage + 1 : props.gwPage - 1;
  const dirGW = props.button_type === "up" ? ">>" : "<<";
  const hideGW = newGW === 0 ? "hidden" : newGW === 46 ? "hidden" :"visible";
  const btnClass = props.button_type === "up" ? styles.btnRight : styles.btnLeft;
  return <Button className ={btnClass} variant="dark" size="lg" onClick={() => props.setUrl(props.newUrl(newGW))} style ={{visibility:hideGW}}>{dirGW}</Button>;
}

function GameHeader(props) {
  // const src_home = "https://platform-static-files.s3.amazonaws.com/premierleague/badges/t"+ {props.game_details.t1no_code}+".svg";
  const src_home = "https://platform-static-files.s3.amazonaws.com/premierleague/badges/t" +
  props.game_details.h_fpl_tcode +
  ".svg";
    // "https://cdn.ff.sbgservices.com/teams/"+props.game_details.homeUuid+".png"
  const src_away = "https://platform-static-files.s3.amazonaws.com/premierleague/badges/t" +
  props.game_details.a_fpl_tcode +
  ".svg";
    // "https://cdn.ff.sbgservices.com/teams/"+props.game_details.awayUuid+".png"
  const src_default =
    "https://www.dreamteamfc.com/g/img/squads/logo/default_5.png";
  const ko_f2 =
    props.game_details.period === "FullTime"
      ? "Full Time"
      : props.game_details.period === "PreMatch"
      ? props.game_details.ko_time
      : props.game_details.matchTime + " Mins";
    const score =
    props.game_details.period === "PreMatch"
      ? "v"
      :props.game_details.h_sc + "-"+props.game_details.a_sc


  return (
    <Container fluid>
      <Row key={props.game_details.indx + "header"}>
        <Col xs={1} style = {{paddingLeft: "0"}}>
          <img
            width={50}
            height={50}
            src={src_home}
            className="img-responsive"
            style = {{float: "left"}}
            onError={(e)=>{e.target.onerror = null; e.target.src=src_default}}
          />
        </Col>
        <Col xs={4}>
          <h2 className={styles.homeh1}> {props.game_details.homeName} </h2>
        </Col>
        <Col xs={2}>
          <h2 className={styles.sc}> {score} </h2>
        </Col>
        <Col xs={4}>
          <h2 className={styles.awayh1}> {props.game_details.awayName} </h2>
        </Col>
        <Col xs={1} style = {{paddingRight: "0"}}>
          <img
            width={50}
            height={50}
            src={src_away}
            className="img-responsive"
            style = {{float: "right"}}
            onError={(e)=>{e.target.onerror = null; e.target.src=src_default}}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h5> {ko_f2} </h5>
        </Col>
      </Row>
    </Container>
  );
}

// className={
//   plyr.red_cards !== 0
//     ? "table-danger"
//     : plyr.yellow_cards !== 0
//     ? "table-warning"
//     : null
// }

function PlayersTable(props) {

  if(props.players.length==0){
    return null
  }
  else{

  return (
    <Table
      key={props.fid + props.type}
      size="sm"
      className={styles.players_table + " shadow"}
    >
      <thead className="table-dark">
        <tr>
          <th style={{textAlign:"left"}}>Player</th>
          <th>Pts</th>
        </tr>
      </thead>
      <tbody>
          {props.players.map(plyr => (
          <tr key={plyr.id} style={{padding:"0"}}>
            <td
              style={{textAlign:"left"}}
            >
              {displayName2(plyr.lastName,[plyr.goals,plyr.assists,plyr.yellow_cards,plyr.red_cards,plyr.pen_save,plyr.pen_miss,plyr.own_goals,plyr.motm])}
            </td>
            <td
              className={
                plyr.goals > 0 || plyr.assists > 0
                  ? "table-success font-weight-bold"
                  : null
              }
            >
              {plyr.pts}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
}

function ActionsTable(props) {
  // if(props.acts_new2.length==0 || props.acts_new2==null || props.acts_new2==""){
    // if(Object.keys(props.acts_new2).length == 0){
    if(props.acts_new2.summary.show == 0){
    return null;
  }
    else {
  return (
    <Table key={props.fid2 + "actionsv2"} size="sm" className={styles.actions_table + " shadow"}>
      <thead className="table-dark">
        <tr className="d-flex">
          <th className="col-4">Home</th>
          <th className="col-4"></th>
          <th className="col-4">Away</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(props.acts_new2).map(([key, value]) => {
          if(value.include==0){
            return null;
          }
          else {
          return (
          <tr key={props.fid2 + key} className="d-flex">
            <td className="col-4">{value.h}</td>
            <td className="col-4">
              <strong>{value.label}</strong>
            </td>
            <td className="col-4">{value.a}</td>
          </tr>
        )}})}
      </tbody>
    </Table>
  );
}
}

function displayName(h_a_input, h_a_type, name, star) {
  if (h_a_input === h_a_type && star == 1) {
    return '\u2B50 ' + name + ' \u2B50';
  }
  else if (h_a_input === h_a_type) {
    return name;
  } else {
    return null;
  }
}

function displayName2(name, actions) {
  var name2=name;
    if(actions[0]>0){
      for (let i = 0; i < actions[0]; i++) {
    name2+=" \u26BD"
        }
      }
    if(actions[1]>0){
      for (let i = 0; i < actions[1]; i++) {
    name2+=" \u2197"
        }
      }
      if(actions[2]>0){
      name2+=" \ud83d\udfe8"
        }
        if(actions[3]>0){
        name2+=" \ud83d\udfe5"
          }
          if(actions[4]>0){
            for (let i = 0; i < actions[4]; i++) {
          // name2+=" \ud83d\udd90"
          name2+=" \ud83e\udde4"
              }
            }
            if(actions[5]>0){
              for (let i = 0; i < actions[5]; i++) {
            name2+=" \u274c"
                }
              }
              if(actions[6]>0){
                for (let i = 0; i < actions[6]; i++) {
              name2+=" \u2B55"
                  }
                }

      if(actions[7]>0){
      name2+=" \u2B50"
    }

return name2;
}


function BonusTable(props) {

if(props.bonus_rank.length==0){
  return null;
}
  else{

  return (
    <Table key={props.indx + "bps"} size="sm" className={styles.bonus_table + " shadow"}>
      <thead className="table-dark">
        <tr className="d-flex">
          <th className="col-5">Home</th>
          <th className="col-2" style={{paddingLeft:"0",paddingRight:"0"}}>Rating</th>
          <th className="col-5">Away</th>
        </tr>
      </thead>
      <tbody>
        {props.bonus_rank.map(bonus => (
          <tr
            key={bonus.indx + bonus.last_name}
            className={
              bonus.star > 0
                ? "d-flex table-success font-weight-bold"
                : bonus.rat7 > 0
                ? "d-flex fw-bold"
                : "d-flex"
            }
          >
            <td className="col-5">
              {displayName(bonus.h_a, "h", bonus.last_name, bonus.star)}
            </td>
            <td className="col-2">{bonus.rat}</td>
            <td className="col-5">
              {displayName(bonus.h_a, "a", bonus.last_name, bonus.star)}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
}


var gwLiveFlag = 1;
var gwLive = 0;
var gwPage = 0;
// const live_url = 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/fpl_alerts_aws_live_19';
const live_url = 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/sky_live';

const useMatchesState = () => {
  // const [gw, setGW] = useState(0);
  const [url, setUrl] = useState(live_url)
  const [responseData, setResponseData] = useState([]);

  //PLAYER POP UP STATE
  // const [show, setShow] = useState(false);
  // const [playerData, setPlayerData] = useState([]);
  //
  // const handleClose = () => setShow(false);
  // const handleShow = (newPlayerData) => {
  //
  //   setPlayerData(newPlayerData);
  //   setShow(true);
  //   };

//END

  const newUrl = (gw_x) => {
    if(gw_x === gwLive){
      return 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/sky_live';
    }
      else {
        return 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/sky_live?gw='+gw_x;
      }
    }
  var fixtures = [];
  var gwTime = "";


  if (Object.keys(responseData).length > 0) {

    fixtures = responseData.fixtures;
    gwLiveFlag = responseData.status.gw_live;
    // gwLiveFlag = 1;

    if(gwLiveFlag===1){
      gwLive=responseData.status.gw;
      // gwLive=10;
    }

    gwPage = responseData.status.gw*1;
    // gwPage = 10;
    gwTime = responseData.upd_time;
    console.log("gwPage:" + gwPage);
  }

  const fetchFpl = async (url) => {

    console.log('fetch_url:'+url)

    const response = await axios.get(url);
    setResponseData(response.data);
    console.log("fetchran");
    // setGW(response.data.status.gw);
  };

  useEffect(() => {
    fetchFpl(url);
    // setGW(responseData.data.status.gw);
    console.log("useeffect ran");
    const intervalId = setInterval(() => {  //assign interval to a variaable to clear it
      fetchFpl(url);
      console.log("useeffect ran again");
    }, 60000)

    return () => {
      clearInterval(intervalId); //This is important
      console.log("clearInterval");
    }

  }, [url])
  // },)

// return {gwPage, gwTime, setUrl, newUrl, fixtures,show,handleShow,handleClose,playerData};
return {gwPage, gwTime, setUrl, newUrl, fixtures};
}

const Matches = () => {

  const {
    gwPage,
    gwTime,
    setUrl,
    newUrl,
    fixtures,
  } = useMatchesState();

  const fixLength = fixtures.length;
  const fix0 = fixtures.slice(0, 1);
  // const fix00 = fix0[0];
  const fix1 = fixtures.slice(1, 2);
  const fixrem = fixtures.slice(2, fixLength);


  return (
    <Container fluid >
      <Row>
        <Col xs={{span:12, order:2}} xl={{span:3, order:1}} style={{ marginLeft: 0 }}>
        <SnackAd ad_type = "skyscraper" ad_num = {1}/>
        </Col>
        <Col xs={{span:12, order:1}} xl={{span:6, order:2}} className={styles.App2}>
          <Container className ={styles.gwInfo}>
            <Row>
              <Col>
                <h1 className ={styles.gameweekh1}> Sky Fantasy Football</h1>
              </Col>
            </Row>
          </Container>
          <GwInfo gwPage={gwPage} setUrl={setUrl} newUrl = {newUrl} gwTime = {gwTime} />
          <div style={{ marginBottom: 30 }}>
            {fix0.map((item, index) => {
              return (
            <React.Fragment key={`rf-${index}`}>

              <Container fluid key={item.index} className={styles.match_all + " shadow-lg"}>
                <GameHeader game_details={item} />

                <Row style = {{marginLeft: "0", marginRight:"0"}}>
                  <Col xs={6} sm={4} className={styles.home_col + " order-sm-1"}>
                    <PlayersTable
                      players={item.home_players}
                      fid={item.indx}
                      type="h"
                    />
                  </Col>
                  <Col xs={6} sm={4} className={styles.away_col + " order-sm-3"}>
                    <PlayersTable
                      players={item.away_players}
                      fid={item.indx}
                      type="a"
                    />
                  </Col>
                  <Col xs={12} sm={4} className={styles.actions_col + " order-sm-2"}>
                      <ActionsTable acts_new2={item.acts_new} fid2={item.indx} />
                      <BonusTable bonus_rank={item.bonus_rank} fid={item.indx} />
                  </Col>
                </Row>
              </Container>
              </React.Fragment>
            )})}
          </div>

          <Row>
            <Col>
              <SnackAd ad_type = "leader" ad_num = {2}/>
              <br></br>
            </Col>
          </Row>

          <div style={{ marginBottom: 30 }}>
            {fix1.map((item, index) => {
              return (
              <React.Fragment key={`rf-${index}`}>

              <Container fluid key={item.index} className={styles.match_all + " shadow-lg"}>
                <GameHeader game_details={item} />

                <Row style = {{marginLeft: "0", marginRight:"0"}}>
                  <Col xs={6} sm={4} className={styles.home_col + " order-sm-1"}>
                    <PlayersTable
                      players={item.home_players}
                      fid={item.indx}
                      type="h"
                    />
                  </Col>
                  <Col xs={6} sm={4} className={styles.away_col + " order-sm-3"}>
                    <PlayersTable
                      players={item.away_players}
                      fid={item.indx}
                      type="a"
                    />
                  </Col>
                  <Col xs={12} sm={4} className={styles.actions_col + " order-sm-2"}>
                      <ActionsTable acts_new2={item.acts_new} fid2={item.indx} />
                      <BonusTable bonus_rank={item.bonus_rank} fid={item.indx} />
                  </Col>
                </Row>
              </Container>
              </React.Fragment>
            )})}
          </div>

          <Row>
            <Col>
              <SnackAd ad_type = "leader" ad_num = {5}/>
              <br></br>
            </Col>
          </Row>

          <div style={{ marginBottom: 30 }}>
            {fixrem.map((item, index) => {
              return (
              <React.Fragment key={`rf-${index}`}>

              <Container fluid key={item.index} className={styles.match_all + " shadow-lg"}>
                <GameHeader game_details={item} />

                <Row style = {{marginLeft: "0", marginRight:"0"}}>
                  <Col xs={6} sm={4} className={styles.home_col + " order-sm-1"}>
                    <PlayersTable
                      players={item.home_players}
                      fid={item.indx}
                      type="h"
                    />
                  </Col>
                  <Col xs={6} sm={4} className={styles.away_col + " order-sm-3"}>
                    <PlayersTable
                      players={item.away_players}
                      fid={item.indx}
                      type="a"
                    />
                  </Col>
                  <Col xs={12} sm={4} className={styles.actions_col + " order-sm-2"}>
                      <ActionsTable acts_new2={item.acts_new} fid2={item.indx} />
                      <BonusTable bonus_rank={item.bonus_rank} fid={item.indx} />
                  </Col>
                </Row>
              </Container>
              </React.Fragment>
            )})}
          </div>

        </Col>
        <Col xs={{span:12, order:3}} xl={{span:3, order:3}} style={{ marginRight: 0 }}>
        <SnackAd ad_type = "skyscraper" ad_num = {2}/>
        </Col>
      </Row>
    </Container>
  );
}

export default Matches;
