import React from 'react'

import Header from '../components/HeaderUcl'
import Footer from '../components/Footer'
import Matches from '../components/Sky'
// import Matches from '../components/MatchesEuro'
import HeadTags from '../components/Headtags6'
// import GoogleAd from '../components/GoogleAd'
import SnackAd2 from '../components/SnackAd2'
import SnackAd from '../components/SnackAd'
import {Container,Row} from "react-bootstrap"


const SkyPage = () => (
  <>
    <HeadTags />
    <div style ={{position:"relative", minHeight:"100vh", backgroundColor:"#062454"}}>
    <Row>
        <SnackAd2 ad_type = "leader" ad_num = {1}/>
    </Row>
      <Header />
      <Matches />
      <Container fluid>
        <Row>
          <SnackAd ad_type = "leader" ad_num = {4}/>
        </Row>
      </Container>
      <Footer />
    </div>
  </>
)

export default SkyPage
